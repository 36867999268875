<template>
  <div>
     <br>
     <br>
    <el-row>
      <div style="float: left;">
        <el-button
       style="float: left; margin: 7px"
       size="mini"
       type="primary"
       icon="el-icon-plus"
       @click="handleAdd()"
       v-if="deviceIds!=''"
     >
       新增電表契約
     </el-button>
      </div>
       <div style="float: right;">
        <el-select v-model="controllerId" :placeholder="$t('ChargingStationS')"  size="small" class="filter-item-mid" width="140px" clearable>
        <el-option v-for="item in controllerOption" :key="item.controlId"
                  :label="item.stationName + ' (控制器:' + item.controlId + ')'" :value="item.controlId" />                
        </el-select>

                                        <!--替換:選擇設備(電表)-->
      <el-select v-model="deviceIds" placeholder="選擇電表" 
         clearable size="small" class="filter-item-mid" width="140px" >
        <el-option v-for="item in devOption" :key="item.devId"
                  :label="item.devName + ' (id:' + item.devId + ')'" :value="item.devId" />
      </el-select>
      <el-button
       style="float: right; margin: 0px"
       size="small"
       type="primary"
       icon="el-icon-search"
       @click="searchSetting()"
      
     >
       查詢
     </el-button>
      </div>
     </el-row>
     
     <br>
     <br>
     <el-row>
     <!-- table -->
     <el-table
       :data="recordList" style="width: 100%;"
       :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
     >
       <el-table-column align="center" prop="meterName" label="電表"  sortable />
       <el-table-column align="center" prop="name" label="契約名稱"  sortable />
       <el-table-column align="center" label="電價類別" >
        <template scope="scope">
         <div v-if="scope.row.type==9">
          <p> {{'低壓電力電動車充換電設施電價' }} </p>
         </div>
         <div v-else>
          <p> {{scope.row.type }} </p>
         </div>
        </template>
       </el-table-column>

       <el-table-column align="center" label="起訖日設定" sortable >
        <template scope="scope">
          <p> {{ scope.row.startDate+" ~ "+ scope.row.endDate}}</p>
        </template>
       </el-table-column>
    
       <el-table-column align="center" prop="message" label="設定"  >
       <template slot-scope="scope">
               <el-button  type="success" size="mini" icon="el-icon-edit"  @click="settingContract('modify',scope.row.deviceId, scope.row.id,scope.row.name,scope.row.startDate,scope.row.endDate,scope.row.peakDemand)"/>
           </template>
       </el-table-column>
     </el-table>

     <!--分頁組件-->
     <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="size" @pagination="searchRecord('2')" :page-sizes="[10]"/>
     </el-row>

     <el-dialog width="1200px"  :visible.sync="dialogSettingVisible" @close="dialogOnClose()">
      <el-card class="box-card" shadow="never">
      
      <span class="filter-label" style="font: 2em sans-serif;">設定電表契約</span>
      <el-divider content-position="right"></el-divider>
      <el-col :xs="24" :sm="24" :md="16" :lg="12" :xl="12" style="margin-bottom: 10px; padding: 7px;">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span class="role-span">歷史契約紀錄</span>
      </div>
     <el-table
      :data="historyList" style="width: 100%;"
       :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
       >
      <!-- <el-table-column align="center" prop="name" label="契約名稱"   />-->
       <el-table-column  align="center" label="契約名稱" :show-overflow-tooltip="true">
            <template slot-scope="props">
              <span class="underline" text-decoration: underline @click="setContractData(props.row.id,props.row.name,props.row.startDate, props.row.endDate,props.row.peakDemand)" >{{props.row.name}}</span>
            </template>
          </el-table-column>

       <el-table-column align="center" label="電價類別"  sortable >
       <template scope="scope">
         <div v-if="scope.row.type==9">
          <p> {{'低壓電力電動車充換電設施電價' }} </p>
         </div>
         <div v-else>
          <p> {{scope.row.type }} </p>
         </div>
        </template>
       </el-table-column>
       <el-table-column align="center" label="起訖日期"  sortable >
       <template scope="scope">
          <p> {{ scope.row.startDate+" ~ "+ scope.row.endDate}}</p>
        </template>
       </el-table-column>
       <el-table-column align="center"  label="刪除"  >
       <template scope="scope">
               <el-button  type="success" size="mini" icon="el-icon-delete"  @click="deleteContract(scope.row.id,scope.row.deviceIds)"/>
           </template>
       </el-table-column>
     </el-table>
    
    </el-card>
  </el-col>

  <!-- 目錄權限設定 -->
  <el-col :xs="24" :sm="24" :md="8" :lg="12" :xl="12" style="padding: 7px;">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
          <span class="role-span">契約變更</span>
      </div>
      <el-row :gutter="2">

        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" style="text-align: left; padding-top: 15px;">
          <span class="data-label">契約名稱:</span>
          <el-input  v-model="contractName" size="small"  class="filter-item-long"  clearable />
        </el-col>
        <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20" style="text-align: left; padding-top: 15px;">
          <span class="data-label">電價類別: </span>
          <span >低壓電力電動車充換電設施電價</span>
        </el-col>
     
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" style="text-align: left; padding-top: 15px;">
          <span class="data-label">起訖日期:</span>
          <el-date-picker v-model="dataRange" style="width: 300px;" type="daterange" value-format="yyyy-MM-dd"
          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="起始日期"
          end-placeholder="結束日期"  clearable>
        </el-date-picker>
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" style="text-align: left; padding-top: 15px;">
          <span class="data-label">平均需量(kw):</span>
          <el-input v-model="amount" size="small"  class="filter-item-long"  placeholder="大於1000的整數" />
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" style="text-align: left; padding-top: 15px;">
          <span class="data-label">監控點編號:</span>
          <el-input v-model="tagid" size="small"  class="filter-item-long"  disabled />
        </el-col>
    </el-row>

    <br>
    <br>
      <el-button size="small" @click="updateData()">
        儲存
      </el-button>
      <el-button size="small"  @click="clearData()">
        重設
      </el-button>

    </el-card>
  </el-col>
</el-card>
  </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';

/* 每隔10min刷新出jwt token*/
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import * as main from "@/utils/main";
import {apiUrl} from "@/utils/main";
const authUrl = main.authUrl();
const refreshTokenUrl = authUrl + "refreshToken";
const refreshTokenInterval = 10*60*1000;     // 10分鐘

export default {
name: 'metercontract',
mixins: [mixins],
data() {
  return {
    controllerId    : undefined,
    controllerIdList: '',//用以紀錄全部的ID
    controllerOption: undefined,
    meterContract:undefined,
    tagidOption:undefined,
    strURL:undefined,
   //deviceIds       : [], //因應複選 故要用陣列
    contractId:undefined, 
    deviceIds       : "", 
    devOption       : undefined, 
    
    isFromAdd:false,

    //新增契約的id
    contractName: undefined,
    dataRange: [],
    amount: undefined,

    total:undefined,
    paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
    serviceId: this.$store.state.currentAccount.serviceInfo.id,
    recordList:[],
    historyList:[],
    isShowAdd:false,
    dialogSettingVisible: false,
    listQuery: { 
              //serviceId: this.$store.state.currentAccount.id,  
              serviceId: this.$store.state.currentAccount.serviceInfo.id,
              deviceIds  :  undefined,  
              sampleRate :  undefined, //undefined
              startDate  :  this.$moment().subtract(40, "days").format("YYYY-MM-DD"),//30天前,
              endDate    :  this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//15天前
              page       : 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
              size       : 10,
          },
  };
},
watch: {
  paginationSelectedPage: {
    handler: function(newValue) {
      this.listQuery.page = newValue - 1
    }
  },
  'controllerId': function (val) {
    this.listQuery.page = 0;
    this.listQuery.deviceIds = undefined;
    //this.resetPage(this.getList);
    //this.listAll= [];
   // this.listSum= null;
    this.deviceIds = [];
    this.devOption=null;
    /* 若充電站(控制器)有被選擇,則才會找該充電站(控制器)所屬的設備電表,沒被選擇則stationId為0 */
    if( val != null && val != undefined && val > 0 ) {
     // console.log("GET:/api/v1/metercontract/deviceidList?controlerId="+val);
      this.axios.get("/api/v1/metercontract/deviceidList?controlerId="+val).then(
          res => {
        //  console.log("Response:deviceidList [res.data]",res.data);
          this.devOption=res.data;
          
          //this.controllerIdList=res.data.controlId
          }
          );
    }
   }, 
   /*'tagid':function(val){
    
   },*/
},
async created() {
  this.getStationAndControllerByServiceId();
},
beforeDestroy() {
  
},
mounted() {
  window.onresize = () => {
    return (() => {
      this.dialogWidth = this.setDialogWidth()
    })()
  }
},
methods: {
  getTagIdList(val){
      this.axios.get("/api/v1/metercontract/tagidList?deviceIds="+val).then(
          res => {
         // console.log("Response:tagidList [res.data]",res.data);
          this.tagid=res.data[0]
         // console.log("2:   /api/v1/metercontract/tagidList?deviceIds="+this.deviceId);
          }
          );
  },
  getStationAndControllerByServiceId() { 
   //console.log("GET:/api/v1/metercontract/controleridList?serviceId="+this.listQuery.serviceId);
    this.axios.get("/api/v1/metercontract/controleridList?serviceId="+this.listQuery.serviceId).then(
        res => {
       // console.log("Response:/api/v1/metercontract/controleridList [res.data]",res.data);
        this.controllerOption=res.data; 
        
          for(var i=0;i<res.data.length;i++)
          {

          if(this.controllerIdList=='')
            this.controllerIdList=res.data[i].controlId
          else
            this.controllerIdList=this.controllerIdList+',' + res.data[i].controlId       

          }
    
         // console.log("this.controllerIdList",this.controllerIdList);
        }
       );
  }, 
  handleAdd(){    //新增刪除頁面
  this.dialogSettingVisible=true
  this.getTagIdList(this.deviceIds)
  this.getContractList()//開啟畫面
  this.contractName='',
  this.dataRange=[]
  this.id=0,
  this.deviceId=this.deviceIds
  this.amount=''
  this.isFromAdd=true
  this.contractId=0
 // this.deviceId=strDeviceId
 //getTagIdList
 //this.getTagIdList(this.deviceId)
  },

  getContractList(strDeviceId){
   // console.log("api/v1/metercontract/subcontractdeviceidList?deviceIds=2689 ");
   this.strURL="/api/v1/metercontract/subcontractdeviceidList?deviceIds="+strDeviceId
   // this.axios.get("api/v1/metercontract/subcontractdeviceidList?deviceIds=3261").then(
   this.axios.get(this.strURL).then(
        res => {
        //console.log("Response:/api/v1/metercontract/subcontractdeviceidList [res.data]",res.data.content);
        this.historyList=res.data.content; 
        }
       );
  },
  searchSetting(){
    this.recordList=null
    //console.log("searchSetting");
    if(this.controllerId==undefined)  //如果都沒選
    {
      this.strURL="/api/v1/metercontract/contractdeviceidList?controlerId="+this.controllerIdList
    }
    else
    {
      if(this.deviceIds=="")//如果只選控制器
      {
        this.strURL="/api/v1/metercontract/contractdeviceidList?controlerId="+this.controllerId
      }
      else
      {
        this.strURL="/api/v1/metercontract/contractdeviceidList?controlerId="+this.controllerId+"&deviceIds=" +this.deviceIds  
      }
    }
   // console.log("this.strURL", this.strURL);
    this.axios.get(this.strURL).then(
        res => {
       // console.log("/api/v1/metercontract/contractdeviceidList?controlerId=1092&deviceIds=3261 ",res.data);
        this.recordList=res.data.content; 
        for (var i=0;i<this.recordList.length; i++)
        {
         // console.log("this.", this.recordList[i].type);
        }
        }
       );
       this.getTagIdList(this.deviceIds)   //bowa add  0719
  },

  settingContract(strModifyType,strDeviceId, strId, strName,strStartDate,strEndDate,peakAmount)   //scope.row.id,scope.row.name,scope.row.startDate,endDate
  {
    this.dialogSettingVisible=true,
    this.getContractList(strDeviceId)//開啟畫面
    //console.log(strStartDate);
   // console.log(strEndDate);
    this.dataRange=[]
    this.contractId=strId,
    this.deviceId=strDeviceId,
    this.contractName=strName,
    this.dataRange[0]=strStartDate,
    this.dataRange[1]=strEndDate,
    this.amount=peakAmount
   
   //this.getTagIdList(this.deviceId)   //bowa add

  },
  deleteContract(strID)
  {
   // console.log("deleteContract");
    this.strURL="/api/v1/metercontract/delcontractdeviceid?Id="+strID +"&tagid="+this.tagid  //加tagid
   // console.log("this.strURL", this.strURL);
    this.axios.get(this.strURL).then(
        res => {
        //  console.log("res",res.data);
          if(res.data.status==200)
          {
             this.$message({ message: res.data.data, type: 'warning' })
           //  console.log("this.deviceId",this.deviceId);
             this.getContractList(this.deviceId)
          }
          else
             this.$message({ message: res.data.data, type: 'warning' })
        }
       );
  },
  updateData()
  {
   // console.log("this.strURL", this.strURL);
    if(this.tagid==null)
    {
      this.$message({ message: "請到'IoT電表設定'新增監控點,並設定為平均需量", type: 'warning' })
    }
    else{
      this.strURL ="/api/v1/metercontract/addcontractdeviceid"
    //  console.log("this.deviceId,", this.deviceId);
      this.axios.post(this.strURL, {
      id: this.contractId,
      deviceId: this.deviceId,
      type: 9,
      name: this.contractName,
      startDate: this.dataRange[0],
      endDate: this.dataRange[1],
      peakDemand:this.amount,
      controlId:this.controllerId,
      serviceId:this.serviceId,
      tagid:this.tagid
    })
    .then(response => {
     // console.log("response.data.status", response.data.status);
      if(response.data.status === 200){
        this.getContractList(this.deviceId)
        this.$message({ message: response.data.data, type: 'warning' })
      }
      else
      this.$message({ message: response.data.data, type: 'warning' })
  })
    }
     
   
},
  clearData()
  {
   // console.log("clearData");
    this.contractName=''
    this.dataRange=[]
    this.amount=''
  },

  setContractData(strId, strName,strStart,strEnd, strAmount)
  {
   // console.log("strStart:",strStart);
   // console.log("strEnd",strEnd);
    this.isFromAdd=false  //不是從新增來的, 是從點選契約名稱來的
    this.dataRange=[]
    this.contractId=strId,
    this.contractName=strName,
    this.dataRange[0]=strStart
    this.dataRange[1]=strEnd
    this.amount=strAmount
   // console.log(" this.dataRange[0]:", this.dataRange[0]);
   // console.log(" this.dataRange[1]", this.dataRange[1]);
    this.getTagIdList(this.deviceId)
  },
  dialogOnClose()
  {
   // console.log("dialogOnClose:");
    this.searchSetting()
  }
}

}
</script>

<style lang="scss" scoped>
.checkbox {
zoom:100%;
}
.alert {
zoom:100%;
}


.data-label {
width: 100px; display: inline-block;
}
.underline{
    text-decoration: underline;
    font-size: 15px;
    color: blue
  }
</style>
